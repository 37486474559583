import React from "react";
import { Avatar, Popover } from "antd";
import { useAuth } from "../../authentication";
import { Link, useHistory } from "react-router-dom";

const UserProfile = () => {
  const { userSignOut } = useAuth();
  const history = useHistory();
  const basePath = process.env.REACT_APP_API_URL;

  const onLogoutClick = () => {
    localStorage.clear();
    userSignOut(() => {
      history.push("/");
    });
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>
        <Link to="/myAccount">My Account</Link>
      </li>
      <li onClick={onLogoutClick}>Logout</li>
    </ul>
  );
  const UserType = () => {
    const userTypeId = localStorage.getItem("userTypeId");

    let userType = "";
    switch (parseInt(userTypeId, 10)) {
      case 1:
        userType = "Super Admin";
        break;
      case 2:
        userType = "Admin";
        break;
      case 3:
        userType = "Merchant";
        break;
      case 4:
        userType = "Sub Merchant";
        break;
      case 5:
        userType = "User";
        break;
      case 6:
        userType = "Driver";
        break;
      default:
        userType = "";
    }
    return userType;
  };
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    flexDirection: 'column',
  };

  const avatarStyle = {
    aspectRatio: 1,
    width: "20%",
    height: "20%",
    cursor: 'pointer',
    marginBottom: "10px",
    borderRadius: '20%',
  };

  const userTypeStyle = {
    fontSize: '16px',
    color: '#333',
    fontWeight: '500'
  };
  return (
    <div style={containerStyle}>
      <Avatar
        src="/assets/images/sophaliSvg.svg"
        alt="Sophali Logo"
        style={avatarStyle}
      />
      <span style={userTypeStyle}>{UserType()}</span>
    </div>

  );
};

export default UserProfile;
