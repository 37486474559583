import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";

import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import { AuthProvider } from "./authentication";
import { SnackbarProvider } from "notistack";

const store = configureStore();
const snackbarOptions = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

const NextApp = () => (
  <SnackbarProvider {...snackbarOptions} maxSnack={3}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AuthProvider>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </AuthProvider>
      </ConnectedRouter>
    </Provider>
  </SnackbarProvider>
);

export default NextApp;
