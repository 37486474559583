import { useEffect, useState } from "react";
import { httpClient } from "../../../util/Api";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import {
  setAdminTax,
  setAdminWithdrawFee,
} from "../../../appRedux/actions/Common";

export const useProvideAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState("");
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const basePath = process.env.REACT_APP_API_URL;

  const fetchStart = () => {
    setLoading(true);
    setError("");
  };

  const fetchSuccess = (message) => {
    setLoading(false);
    enqueueSnackbar(message, { variant: "success" });
    setError("");
  };

  const fetchError = (error) => {
    setLoading(false);
    enqueueSnackbar(error, { variant: "error" });
    setError(error);
  };

  const userLogin = (user, callbackFun) => {
    fetchStart();
    fetchAllPermission();

    httpClient
      .post("login", user)
      .then(({ data }) => {
        if (data) {
          fetchSuccess("Successfully logged in");
          httpClient.defaults.headers.common["Authorization"] =
            "Bearer " + data.token;
          localStorage.setItem("token", data.token);
          localStorage.setItem(
            "userPermissions",
            JSON.stringify(data.userDetail.UserRoles[0].Role.RolePermissions)
          );
          getAuthUser();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        console.log(`🚀🚀🚀  error:`, error);
        if (error.response.data.message)
          fetchError(error.response.data.message);
        else fetchError(error.message);
      });
  };

  const userSignup = (user, callbackFun) => {
    fetchStart();
    httpClient
      .post("register", user)
      .then(({ data }) => {
        if (data.status) {
          fetchSuccess();
          history.push("/login");
          // localStorage.setItem('token', data.token.access_token);
          // httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
          // getAuthUser();
          // if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const sendPasswordResetEmail = async ({ email }) => {
    fetchStart();
    try {
      let response = await axios.post(`${basePath}/users/forgot-password`, {
        email,
      });
      if (response) {
        fetchSuccess("Kindly check email for reset password link");
      }
    } catch (error) {
      fetchError(error.response.data.message);
    }
  };

  const confirmPasswordReset = async (token, values, callbackFun) => {
    try {
      let response = await axios.post(
        `${basePath}/users/update-forget-password`,
        {
          token,
          password: values.password,
          confirmPassword: values.confirmPassword,
        }
      );

      if (response) {
        fetchSuccess("Password reset successfully");
        history.push("/login");
      }
    } catch (error) {
      fetchError(error.response.data.message);
    }
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = () => {
    try {
      fetchStart();
      fetchSuccess("User logged out successfully");
      httpClient.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      setAuthUser(false);
    } catch (error) {
      // enqueueSnackbar(error, { variant: "error" });
      fetchError(error);
    }
  };

  const getAuthUser = () => {
    fetchStart();
    httpClient
      .post("profile")
      .then(async ({ data }) => {
        if (data.user) {
          localStorage.setItem("userId", data.user.id);
          localStorage.setItem("photo", data.user.company_logo);
          localStorage.setItem("merchant_name", data.user?.company_name);
          localStorage.setItem("userTypeId", data.user.user_type_id);
          if (data.user.user_type_id == 4)
            localStorage.setItem("parent_merchant_id", data.user.parent_id);
          try {
            if (data.user.user_type_id == 4 || data.user.user_type_id == 3) {
              let parent_id = data.user.id;
              if (data.user.user_type_id == 4) {
                parent_id = data.user.parent_id;
              }
              const response = await axios.get(
                `${basePath}/setting/${parent_id}`
              );
              localStorage.setItem("tax", response?.data?.settings.tax);
              const response1 = await axios.get(`${basePath}/setting/1`);
              dispatch(setAdminTax(response1?.data?.settings.tax));
              dispatch(
                setAdminWithdrawFee(response1?.data?.settings.withdraw_fee)
              );
              console.log(`🚀🚀🚀  response1:`, response1);
              localStorage.setItem(
                "withdraw_fee",
                response?.data?.settings.withdraw_fee
              );
            }
          } catch (error) {}
          setAuthUser(data.user);
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        httpClient.defaults.headers.common["Authorization"] = "";
        fetchError(error.message);
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  const fetchAllPermission = async () => {
    try {
      const result = await axios.get(`${basePath}/permissions/all`);

      localStorage.setItem(
        "allPermissionsList",
        JSON.stringify(result.data.permissions.rows)
      );
    } catch (error) {
      // enqueueSnackbar(error, { variant: "error" });
      console.error(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      httpClient.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    httpClient
      .post("profile")
      .then(({ data }) => {
        if (data.user) {
          setAuthUser(data.user);
        }
        setLoadingUser(false);
      })
      .catch(function () {
        localStorage.removeItem("token");
        httpClient.defaults.headers.common["Authorization"] = "";
        setLoadingUser(false);
      });
  }, []);

  // All Permission

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
};
