import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}admin`}
        component={asyncComponent(() => import("../pages/Admin"))}
      />

      <Route
        path={`${match.url}menu`}
        component={asyncComponent(() => import("../pages/Menu"))}
      />
      {/* <Route path={`${match.url}category`} component={asyncComponent(() => import('../pages/Category'))}/>
      <Route path={`${match.url}product`} component={asyncComponent(() => import('../pages/Product'))}/> */}
      <Route
        path={`${match.url}user`}
        component={asyncComponent(() => import("../pages/User"))}
      />
      <Route
        path={`${match.url}role`}
        component={asyncComponent(() => import("../pages/Role"))}
      />
      <Route
        path={`${match.url}permission`}
        component={asyncComponent(() => import("../pages/Permission"))}
      />
      <Route
        path={`${match.url}driver`}
        component={asyncComponent(() => import("../pages/Driver"))}
      />
      <Route
        path={`${match.url}friend`}
        component={asyncComponent(() => import("../pages/Friend"))}
      />
      <Route
        path={`${match.url}merchant`}
        component={asyncComponent(() => import("../pages/Merchant"))}
      />
      <Route
        path={`${match.url}merchantWithdrawRequests`}
        component={asyncComponent(() =>
          import("../pages/MerchantWithdrawalRequest")
        )}
      />
      <Route
        path={`${match.url}adminSubscriptions`}
        component={asyncComponent(() => import("../pages/AdminSubscriptions"))}
      />
      <Route
        path={`${match.url}merchantSubscriptions`}
        component={asyncComponent(() =>
          import("../pages/MerchantSubscriptions")
        )}
      />
      <Route
        path={`${match.url}merchantOrder`}
        component={asyncComponent(() => import("../pages/MerchantOrder"))}
      />
      <Route
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import("../pages/Dashboard"))}
      />
      <Route
        path={`${match.url}merchantAnalytics`}
        component={asyncComponent(() => import("../pages/MerchantAnalytics"))}
      />
      <Route
        path={`${match.url}merchantRedeemOrder`}
        component={asyncComponent(() => import("../pages/MerchantRedeemOrder"))}
      />
      <Route
        path={`${match.url}userOrder`}
        component={asyncComponent(() => import("../pages/UserOrder"))}
      />
      <Route
        path={`${match.url}userToken`}
        component={asyncComponent(() => import("../pages/UserToken"))}
      />
      <Route
        path={`${match.url}notifications`}
        component={asyncComponent(() => import("../pages/Notifications"))}
      />
      <Route
        path={`${match.url}messages`}
        component={asyncComponent(() => import("../pages/Messages"))}
      />
      <Route
        path={`${match.url}adminReceipt`}
        component={asyncComponent(() => import("../pages/AdminReceipt"))}
      />
      <Route
        path={`${match.url}merchantReceipt`}
        component={asyncComponent(() => import("../pages/MerchantReceipt"))}
      />
      <Route
        path={`${match.url}coupons`}
        component={asyncComponent(() => import("../pages/CouponManagement"))}
      />
      <Route
        path={`${match.url}myAccount`}
        component={asyncComponent(() => import("../pages/MyAccount"))}
      />
      <Route
        path={`${match.url}withdrawal`}
        component={asyncComponent(() => import("../pages/Withdrawal"))}
      />
      <Route
        path={`${match.url}delivery`}
        component={asyncComponent(() => import("../pages/Delivery"))}
      />
      <Route
        path={`${match.url}addons`}
        component={asyncComponent(() => import("../pages/Addons"))}
      />
      <Route
        path={`${match.url}CheckedInUsers`}
        component={asyncComponent(() => import("../pages/CheckedInUsers"))}
      />
      <Route
        path={`${match.url}Billing`}
        component={asyncComponent(() => import("../pages/Billing"))}
      />
    </Switch>
  </div>
);

export default App;
