import React, { useEffect, useState } from "react";
import Pusher from "pusher-js";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../authentication";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faBell,
  faEnvelope,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
const basePath = process.env.REACT_APP_API_URL;

const AppsNavigation = () => {
  const history = useHistory();
  const { userSignOut } = useAuth();
  const [count, setCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  let userId = localStorage.getItem("userId");
  useEffect(() => {
    const pusher = new Pusher("d25a6a9053d2a026b528", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("merchant");

    channel.bind(`count-${userId}`, (data) => {
      getNotitifications(userId);
    });

    return () => {
      channel.unsubscribe();
    };
  }, [userId, count]);
  useEffect(() => {
    const merchantId = localStorage.getItem("userId");
    if (merchantId) getNotitifications(merchantId);
  }, []);
  const onLogoutClick = () => {
    localStorage.clear();
    userSignOut(() => {
      history.push("/");
    });
  };
  const getNotitifications = async (userId) => {
    try {
      let stringParams = "";
      if (localStorage.getItem("userTypeId") == "3") {
        stringParams = `merchant_id=${userId}`;
      } else {
        stringParams = `user_id=${userId}`;
      }
      const res = await axios.get(
        `${basePath}/notifications/get-notification-count?` + stringParams
      );
      setCount(res.data.notificationMessages);
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: "error" });
      console.error(error);
    }
  };
  return (
    <ul className="gx-app-nav NoWrap">
      <li>
        <Link to="/notifications">
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon className="icon" icon={faBell} />
            {count > 0 && (
              <span
                style={{ color: "white", fontSize: "12px" }}
                className="gx-notification-badge"
              >
                {count}
              </span>
            )}
          </div>
        </Link>
      </li>
      <li>
        <Link to="/messages">
          <FontAwesomeIcon className="icon" icon={faEnvelope} />
        </Link>
      </li>
      <li>
        <Link to="/myAccount">
          <FontAwesomeIcon className="icon" icon={faUser} />
        </Link>
      </li>
      <li onClick={onLogoutClick}>
        <FontAwesomeIcon className="icon" icon={faSignOutAlt} />
      </li>
    </ul>
  );
};
export default AppsNavigation;
