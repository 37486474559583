import { Button, Form, Input } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";

const ForgotPassword = () => {
  const {
    isLoading,
    error,
    sendPasswordResetEmail: requestPasswordReset,
  } = useAuth();

  const onFinishFailed = (errorInfo) => {
    // Handle form errors
  };

  const onFinish = (values) => {
    requestPasswordReset(values);
  };
  const emailValidation = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-forgot-password-form gx-form-row0"
            >
              <Form.Item
                rules={[
                  { required: true, message: "Please enter your E-mail!" },
                  {
                    pattern: emailValidation,
                    message: "The input is not a valid E-mail!",
                  },
                ]}
                name="email"
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.resetPassword" />
                </Button>
                <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{" "}
                <Link to="/signin">
                  <IntlMessages id="app.userAuth.signIn" />
                </Link>
              </Form.Item>
            </Form>
          </div>
          {/* <AppNotificationContainer loading={isLoading} error={error} /> */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
