import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import { Menu } from "antd";
import React from "react";
import {
  faUser,
  faWallet,
  faShippingFast,
  faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntlMessages from "../../util/IntlMessages";
import AppsNavigation from "./AppsNavigation";
import UserProfile from "./UserProfile";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);
  let userId = localStorage.getItem("userId");

  const basePath = process.env.REACT_APP_API_URL;
  const [planId, setPlanId] = React.useState(null);

  React.useEffect(() => {
    const getSubscription = async () => {
      try {
        const response = await axios.get(`${basePath}/subscriptions/getSubscription?merchant_id=${userId}`);
        setPlanId(response?.data?.subscriptions[0]?.SubscriptionPlan?.id);
      } catch (error) {
        console.error(error);
      }
    };

    if (localStorage.getItem("userTypeId") === "3") {
      getSubscription();
    }
  }, [userId]);

  const itemArray = [
    {
      key: "14",
      label: (
        <Link to="/myAccount">
          <FontAwesomeIcon icon={faUser} />
          <span style={{ marginLeft: 10 }}><IntlMessages id="sidebar.myAccount" /></span>
        </Link>
      ),
    },
    // {
    //   key: "1",
    //   label: (
    //     <Link to="/admin">
    //       <i className="icon icon-widgets" />
    //       <span>
    //         <IntlMessages id="sidebar.admin" />
    //       </span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "2",
    //   label: (
    //     <Link to="/role">
    //       <i className="icon icon-company" />
    //       <span>
    //         <IntlMessages id="sidebar.role" />
    //       </span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: (
    //     <Link to="/menu">
    //       <i className="icon icon-treeselect" />
    //       <span>
    //         <IntlMessages id="sidebar.menu" />
    //       </span>
    //     </Link>
    //   ),
    // },

    // {
    //   key: "4",
    //   label: (
    //     <Link to="/merchant">
    //       <i className="icon icon-wysiwyg" />
    //       <span>
    //         {userTypeId == 3 ? (
    //           <IntlMessages id="sidebar.subMerchant" />
    //         ) : (
    //           <IntlMessages id="sidebar.merchant" />
    //         )}
    //       </span>
    //     </Link>
    //   ),
    // },
    {
      key: "18",
      label: (
        <Link to="/adminSubscriptions">
          <FontAwesomeIcon icon={faCalendarWeek} />
          <span style={{ marginLeft: 10 }}><IntlMessages id="sidebar.subscriptions" /></span>
        </Link>
      ),
    },
    {
      key: "20",
      label: (
        <Link to="/Delivery">
          <FontAwesomeIcon icon={faShippingFast} />
          <span style={{ marginLeft: 10 }}><IntlMessages id="Delivery" /></span>
        </Link>
      ),
    },
    {
      key: "21",
      label: (
        <Link to="/Withdrawal">
          <FontAwesomeIcon icon={faWallet} />
          <span style={{ marginLeft: 10 }}><IntlMessages id="Withdrawal" /></span>
        </Link>
      ),
    },
    // {
    //   key: "19",
    //   // key: "4",
    //   label: (
    //     <Link to="/merchantSubscriptions">
    //       <i className="icon icon-wysiwyg" />
    //       <span>
    //         <IntlMessages id="sidebar.subscriptions" />
    //       </span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "17",
    //   // key: "4",
    //   label: (
    //     <Link to="/merchantWithdrawRequests">
    //       <i className="icon icon-wysiwyg" />
    //       <span>
    //         <IntlMessages id="sidebar.merchantWithdrawRequests" />
    //       </span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "5",
    //   label: (
    //     <Link to="/user">
    //       <i className="icon icon-wall" />
    //       <span>
    //         <IntlMessages id="sidebar.user" />
    //       </span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "5",
    //   label: (
    //     <Link to="/coupons">
    //       <i className="icon icon-wall" />
    //       <span>
    //         <IntlMessages id="Coupons" />
    //       </span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "6",
    //   label: (
    //     <Link to="/driver">
    //       <i className="icon icon-user" />
    //       <span>
    //         <IntlMessages id="sidebar.driver" />
    //       </span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "7",
    //   label: (
    //     <Link to="/friend">
    //       <i className="icon icon-team" />
    //       <span>
    //         <IntlMessages id="sidebar.friend" />
    //       </span>
    //     </Link>
    //   ),
    // },
    // // {
    // //   key: "8",
    // //   label: (
    // //     <Link to="/merchantOrder">
    // //       <i className="icon icon-translation" />
    // //       <span>
    // //         <IntlMessages id="sidebar.merchantOrder" />
    // //       </span>
    // //     </Link>
    // //   ),
    // // },
    // {
    //   key: "16",
    //   label: (
    //     <Link to="/merchantAnalytics">
    //       <i className="icon icon-translation" />
    //       <span>
    //         <IntlMessages id="sidebar.merchantAnalytics" />
    //       </span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "15",
    //   label: (
    //     <Link to="/merchantRedeemOrder">
    //       <i className="icon icon-translation" />
    //       <span>
    //         <IntlMessages id="sidebar.merchantRedeemOrder" />
    //       </span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "9",
    //   label: (
    //     <Link to="/userOrder">
    //       <i className="icon icon-tickets" />
    //       <span>
    //         <IntlMessages id="sidebar.userOrder" />
    //       </span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "10",
    //   label: (
    //     <Link to="/userToken">
    //       <i className="icon icon-tickets" />
    //       <span>
    //         {userTypeId == 3 ? (
    //           <IntlMessages id="sidebar.merchantToken" />
    //         ) : userTypeId == 1 ? (
    //           <IntlMessages id="sidebar.adminToken" />
    //         ) : (
    //           <IntlMessages id="sidebar.userToken" />
    //         )}
    //       </span>
    //     </Link>
    //   ),
    // },

    // {
    //   key: "11",
    //   label: (
    //     <Link to="/adminReceipt">
    //       <i className="icon icon-tickets" />
    //       <span>
    //         <IntlMessages id="sidebar.adminReceipt" />
    //       </span>
    //     </Link>
    //   ),
    // },

    // {
    //   key: "12",
    //   label: (
    //     <Link to="/merchantReceipt">
    //       <i className="icon icon-tickets" />
    //       <span>
    //         <IntlMessages id="sidebar.merchantReceipt" />
    //       </span>
    //     </Link>
    //   ),
    // },
  ];
  
  // if (planId > 1) {
  //   itemArray.splice(4, 0, {
  //     key: "13",
  //     label: (
  //       <Link to="/coupons">
  //         <i className="icon icon-tickets" />
  //         <span>
  //           <IntlMessages id="sidebar.coupons" />
  //         </span>
  //       </Link>
  //     ),
  //   });
  // }
  // let canReadAll = JSON.parse(localStorage.getItem("userPermissions"));
  // canReadAll = canReadAll.filter((item) => {
  //   return item.can_read;
  // });
  // let items = itemArray.filter((item) => {
  //   return canReadAll.find((i) => {
  //     return i.permission_id.toString() === item.key;
  //   });
  // });
  // const getNoHeaderClass = (navStyle) => {
  //   if (
  //     navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
  //     navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
  //   ) {
  //     return "gx-no-header-notifications";
  //   }
  //   return "";
  // };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${navStyle === "no-header-mini-sidebar" ? "gx-no-header-notifications" : ""}`}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === "lite" ? "lite" : "dark"}
            mode="inline"
            items={itemArray.map(item => ({
              key: item.key,
              label: item.label,
              icon: <FontAwesomeIcon icon={item.icon} />
            }))}
          />
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
