import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import Pusher from "pusher-js";
import { Layout, Badge } from "antd";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faStore,
  faChartLine,
  faFileInvoiceDollar,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const { Header } = Layout;

const basePath = process.env.REACT_APP_API_URL;
const links = [
  {
    label: "icon1",
    text: "DASHBOARD",
    Icon: faHome,
    to: "/dashboard",
  },
  {
    label: "icon2",
    text: "MERCHANT",
    Icon: faStore,
    to: "/merchant",
  },
  {
    label: "icon3",
    text: "ANALYTICS",
    Icon: faChartLine,
    to: "/merchantAnalytics",
  },
  {
    label: "icon4",
    text: "BILLING",
    Icon: faFileInvoiceDollar,
    to: "/Billing",
  },
  {
    label: "icon5",
    text: "USERS",
    Icon: faUsers,
    to: "/user",
  },

];
const TopBar = () => {
  const [orderCount, setOrderCount] = useState(0);
  const location = useLocation();
  const currentPath = location.pathname;
  const selectedLink = links.find((link) => currentPath === link.to)?.label || links[0].label;
  let userId = localStorage.getItem("userId");

  useEffect(() => {
    const pusher = new Pusher("d25a6a9053d2a026b528", {
      cluster: "ap2",
    });
    const channel = pusher.subscribe("merchant");
    channel.bind(`count-${userId}`, (data) => {
      getOrderCount(userId);
    });
    return () => channel.unsubscribe();
  }, [userId, orderCount]);

  useEffect(() => {
    const merchantId = localStorage.getItem("userId");
    if (merchantId) getOrderCount(merchantId);
  }, []);

  const getOrderCount = async (merchantId) => {
    try {
      const res = await axios.get(`${basePath}/orders/get-order-count?merchant_id=${merchantId}`);
      setOrderCount(res.data.orderCount);
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <Header>
        {links.map(({ label, text, Icon, to, count }) => (
          <Link
            key={label}
            to={to}
            className={`topbar-link ${selectedLink === label ? 'selected' : ''}`}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Badge
              className="my-badge"
              count={count}
              style={{
                padding: 15,
                display: "flex",
                justifyContent: "center",
                overflow: "visible",
              }}
            >
              <FontAwesomeIcon
                icon={Icon}
                style={{
                  fontSize: "28px",
                  margin: "0 auto",
                  color: selectedLink === label ? "#08822A" : "inherit",
                }}
              />
              <div style={{ marginTop: 7 }}>{text}</div>
            </Badge>
          </Link>
        ))}
      </Header>
    </>
  );
};

export default TopBar;
