import {
  SET_ADMIN_TAX,
  SET_ADMIN_WITHDRAW_FEE,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
} from "../../constants/ActionTypes";

export const setAdminTax = (value) => ({
  type: SET_ADMIN_TAX,
  payload: value,
});
export const setAdminWithdrawFee = (value) => ({
  type: SET_ADMIN_WITHDRAW_FEE,
  payload: value,
});
export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS,
  };
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error,
  };
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
