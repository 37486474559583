import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Spin, Alert, Typography, Layout, Card, Avatar } from 'antd';

const { Title } = Typography;
const { Content } = Layout;

const basePath = process.env.REACT_APP_API_URL;

const VerifyPage = ({ match }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const response = await axios.post(`${basePath}/verify`, {
          token: match?.params?.token,
        });

        setIsVerified(response?.data?.status === "success");
      } catch (error) {
        if (error?.response && error?.response?.data && error?.response?.data?.message) {
          setError(error.response.data.message);
        } else {
          setError("An unknown error occurred.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    verifyUser();
  }, [match?.params?.token]);

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Content style={{ textAlign: 'center', padding: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        {isLoading ? (
          <Spin size="large" tip="Verifying your email address..." />
        ) : error ? (
          <Alert
            message="Error!"
            description={error}
            type="error"
              style={{ width: '100%', maxWidth: "90%", }}
          />
        ) : (
          <Card style={{maxWidth:"90%", width: '100%', margin: '0 auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
            <Avatar
              src="/assets/images/sophaliSvg.svg"
              alt="Sophali Logo"
              style={{
                aspectRatio: 1,
                width: "10%",
                height: "10%",
                cursor: 'pointer',
                marginBottom: "10px",
                borderRadius: '20%',
              }}
            />
            <Title level={2}>
              {isVerified
                ? 'Thank you for verifying your email address. You may log in now.'
                : 'Verification failed. Please try again.'}
            </Title>
          </Card>
        )}
      </Content>
    </Layout>
  );
};

export default VerifyPage;
