import { Button, Form, Input } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";

const ResetPassword = () => {
  const { isLoading, error, confirmPasswordReset: resetPassword } = useAuth();
  const { token } = useParams();
  

  const onFinishFailed = (errorInfo) => {
    // Handle form errors
  };

  const onFinish = (values) => {
    resetPassword(token, values);
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-reset-password-form gx-form-row0"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                ]}
                name="password"
              >
                <Input type="password" placeholder="New Password" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The passwords do not match!")
                      );
                    },
                  }),
                ]}
                name="confirmPassword"
              >
                <Input type="password" placeholder="Confirm New Password" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.resetPassword" />
                </Button>
              </Form.Item>
            </Form>
          </div>
          {/* <AppNotificationContainer loading={isLoading} error={error} /> */}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
